import React, { useEffect, useState } from "react";
import { ReactComponent as VectorImage } from "../../assets/images/module.svg";
import VectorDrop from "../../assets/images/module-arrow.png";
import SelfLearningSubmenu from "./SelfLearningSubmenu";
import "./SelfLearning.scss";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { selfLearningVideoUrl } from "examsbook-react-sdk/actions";
import { useDispatch } from "react-redux";
import { LinearProgressWithLabel } from "../MyCourseCard/MyCoursesCard";
import { Box, Button, Typography } from "@material-ui/core";
import { ReactComponent as ExpiringClock } from "../../assets/images/expiringclockcourseblue.svg";
import { ReactComponent as Points } from "../../assets/images/points.svg";
import { useTheme } from "../../constants/themeProvider";
import { useHistory } from "react-router-dom/cjs/react-router-dom"; 
export default function SelfLearningCard({ isDropdownOpen, setIsDropdownOpen, sectionIndex, currentSectionIndex, setCurrentSectionIndex, currentDataIndex, setCurrentDataIndex, selectedSubmenu, setSelectedSubmenu, activeIndex, nextClick, setActiveIndex, index, setCurrentActiveSection, currentActiveSection, setSectionChanged, pdfOpen, openPdfViewer, onPdfOpen, courseId, setShowEvaluationTest, showEvaluationTest, test1, sectionName,courseDataList, isDropDown, setIsDropDown, sectionData, setSectionNumber, setNextClick, setCourseDataList, setSectionName,sectionNumber, handleUpdateCourseProgress,   ...props }) {
  const classes = useStyles(styles)();
  const t = useTranslations();
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();

  const processSectionData = (index) => {
    const newSectionData = sectionData?.data;
    newSectionData?.section?.forEach((section, sectionIndex) => {
      if (sectionIndex >= index) {
        setSectionNumber((prevState) => [
          ...prevState,
          section.courseSectionData.length,
        ]);
        if (sectionIndex === index) {
          setSectionName(section?.title?.en);
          const sectionData = [...section?.courseSectionData];
          setNextClick([
            {
              ...sectionData[0],
              sectionId: section?._id,
              title: section?.title,
            },
          ]);
          sectionData.shift();
          setIsDropDown((prevState) => ({
            ...prevState,
            [sectionIndex]: true,
          }));
        } else {
          setIsDropDown((prevState) => ({
            ...prevState,
            [sectionIndex]: false,
          }));
        }
        setCourseDataList((prevState) => [
          ...prevState,
          ...section?.courseSectionData.map((data1) => ({
            ...data1,
            sectionId: section?._id,
            title: section?.title,
          })),
        ]);
      }
    });
  };
  
  useEffect(() => {
    if (currentSectionIndex !== index) {
      setIsDropdownOpen(false);
      setIsDropDown((prevState) => ({
        ...prevState,
        [index]: false,
      }));
    }
  }, [currentSectionIndex, index, setIsDropdownOpen, setIsDropDown]);
  
  const toggleDropdown = (activeItem, event) => {
    event.stopPropagation();
    setIsDropDown((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === index.toString();
        return acc;
      }, {}),
    }));
    if(!isDropDown?.[index]) {
      processSectionData(index);
    }
    setSectionChanged(true);
    setIsDropdownOpen(!isDropdownOpen);

    const videoData = props.data?.courseSectionData.find(subValue => subValue.refModel === "RecordedVideo");

    dispatch(selfLearningVideoUrl({
      data: {
        percent: videoData?.contentData[0]?.percent,
        _id: videoData?.contentData[0]?._id,
        name: videoData?.contentData[0]?.title,
        contentData: [{
          title: videoData?.contentData[0]?.title,
          description: videoData?.contentData[0]?.description,
          url: videoData?.contentData[0]?.url
        }]
      }
    }));
  };
 
  function handleViewClick(index) {
    processSectionData(index);
    setCurrentSectionIndex(index);
    setCurrentDataIndex(0);
    setShowEvaluationTest(true);
}

  const language = localStorage.getItem('language');

  return (
    <>
      {showEvaluationTest ? (
        <Box>
          <Box
            style={{
              borderBottom: '1px solid #E7E7E7EE',
              borderRadius: '0px',
              minHeight: '20px',
              cursor: 'pointer',
              padding: '5px',
              transition: '0.3s all linear'
            }}
            onClick={(event) => toggleDropdown(props, event)}
          >
            <Box className="innerBox1">
              <Box className="button-box1">
                <Box
                  component="img"
                  style={{ height: '8px', width: '14px' }}
                  className={isDropDown?.[index] ? '' : 'rotated-img'}
                  src={VectorDrop}
                />
              </Box>
              <Box className="card1">
                <Box className="content-box1">
                  <p className={classes.genustext} sx={{ m: 0, mb: 1 }}>
                    {props.data?.title?.en}
                  </p>
                </Box>
              </Box>
              <Box className={classes.timeContent}>{props?.data?.sectionReadTimeCount} MINS</Box>
            </Box>
          </Box>
          {(isDropDown?.[index] || currentSectionIndex === index) && (
        <Box className="dropdown-content">
          {props.data?.courseSectionData !== undefined ? (
            props.data?.courseSectionData.map((item, index) => (
              <SelfLearningSubmenu
                key={index}
                index={index}
                sectionIndex={sectionIndex}
                data={item}
                section={sectionData}
                courseId={courseId}
                handleUpdateCourseProgress={handleUpdateCourseProgress}
                sectionId={props.data._id}
                setSectionChanged={setSectionChanged}
                pdfOpen={pdfOpen}
                onPdfOpen={onPdfOpen}
                activeItem={props?.activeItem}
                selectedSubmenu={selectedSubmenu}
                setSelectedSubmenu={setSelectedSubmenu}
                nextClick={nextClick}
                test1={test1}
                sectionName={sectionName}
                onSubmenuClick={props.onSubmenuClick}
                currentSectionIndex = {currentSectionIndex}
                setCurrentSectionIndex = {setCurrentSectionIndex}
                currentDataIndex = {currentDataIndex}
                setCurrentDataIndex={setCurrentDataIndex}
                openPdfViewer={openPdfViewer}
              />
            ))
          ) : (
            <></>
          )}
        </Box>
      )}
        </Box>
      ) : (
        <Box className={classes.selfLearningBox}
        >
          <Box
            className="outerBox1"
            style={{
              border: isDropDown?.[index] ? '1px solid #E7E7E7EE' : 'none',
              borderRadius: '6px',
            }}
          >
            <Box className="innerBox">
              <Box className="image-box">
                <VectorImage
                  alt="Vector arrow"
                />
              </Box>
              <Box className="card">
                <Box className="content-box">
                  <p className={classes.genustext} sx={{ m: 0, mb: 1 }}>
                    {props?.data?.title?.en}
                  </p>
                </Box>
                <Box style={{ width: '100%' }}>
                  <LinearProgressWithLabel value={props.data.sectionPercent} />
                </Box>
              </Box>
              <Box className="button-box">
                <Box className="minutes-read">
                  <ExpiringClock />
                  <Typography variant="body2">{props?.data?.sectionReadTimeCount} MINS</Typography>
                </Box>
               
              </Box>
              <Button
                  variant="body2"
                  style={{
                    border: `1px solid ${theme?.primaryColor}`,
                    color: `${theme?.primaryColor}`,
                    fontWeight: '600',
                    textTransform: 'capitalize',
                  }}
                  onClick={() => {
                    handleViewClick(index);
                  }}
                >
                  {t('Start Learning')}
                </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

const styles = {
  liveText: {
    color: "#84818A",
  },
  genustext: {
    color: "#84818A",
    fontWeight: 600,
    margin: 0,
    color: "#000000",
    fontSize: "12px",
    textTransform: 'uppercase'
  },
  timeContent: {
    fontSize: "12px",
    fontWeight: 600,
    width: '30%',
    textAlign: 'right'
  },
  selfLearningBox: {
    borderRadius: '8px',
    background: '#FFFFFF',
    margin: '15px 5px',
    boxShadow: '1.95px 1.95px 2.6px 0px rgba(0, 0, 0, 0.15)',
  }
};
