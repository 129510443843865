import React, { useEffect, useState } from "react";
import { Grid, Box, Button, Typography } from "@material-ui/core";
import { LinearProgressWithLabel } from "../../components/MyCourseCard/MyCoursesCard";
import { makeStyles } from "@material-ui/core/styles";
import {
  reactGaEvent,
  recentCourseListAction,
} from "examsbook-react-sdk/actions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { useTheme } from "../../constants/themeProvider";
import moment from "moment";
import { ColorsConstant } from "../../constants/colorConstant";
import OverlayButton from "../../constants/OverlayButton";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: ColorsConstant.white,
    margin: "0px 16px",
    borderRadius: "6px",
  },
  fragment: {
    padding: "16px",
  },
  title: {
    color: ColorsConstant.defaultBlue,
    fontSize: "18px",
    fontWeight: 500,
    "@media (max-width: 767px)": {
      fontSize: "14px",
    },
  },
  subtitle: {
    color: ColorsConstant.subTitleTextColor,
    fontSize: "12px",
    fontWeight: 400,
  },
  mainDiv: {
    padding: "0 6px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    '@media (max-width: 767px)': {
      padding: "10px 3px",
    },
  },
  heading: {
    fontSize: "14px",
    textTransform: "capitalize",
    "@media (max-width: 767px)": {
      fontSize: "10px",
    },
  },
  progressBarAlign: {
    '@media (min-width: 900px) and (max-width: 960px)': {
      marginLeft: "16px",
    },
  },
  noCourseFound: {
    color: "#00000080",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    textTransform: "capitalize",
    '@media (max-width: 600px)': {
      fontSize: "1rem",
    },
  },
  subHeading: {
    color: ColorsConstant.lightGreyText,
    fontSize: "16px",
    fontWeight: 275,
    '@media (min-width: 1600px)': {
      fontSize: "20px",
    },
    '@media (max-width: 900px)': {
      fontSize: "13px",
    },
    '@media (max-width: 600px)': {
      fontSize: "10px",
    },
    '@media (min-width: 900px) and (max-width: 1025px)': {
      fontSize: "12px",
      marginLeft: "16px",
    },
  },
  continueBtn: {
    borderRadius: "6px",
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: 600,
    padding: "4px 10px",
    background: `${ColorsConstant.white} !important`,
    '@media (min-width: 1400px)': {
      padding: "4px 40px",
      minWidth: "max-content",
    },
    '@media (min-width: 900px) and (max-width: 1200px)': {
      fontSize: "14px",
      padding: "4px 12px",
    },
    '@media (max-width: 900px)': {
      fontSize: "14px",
      minWidth: "max-content",
    },
    '@media (max-width: 600px)': {
      fontSize: "12px",
      padding: "4px",
      minWidth: "max-content",
    },
  },
  courseContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    borderBottom: `1px solid ${ColorsConstant.cardBorders}`,
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: 0,
    '@media (max-width: 900px)': {
      justifyContent: "space-between",
    },
    '@media (min-width: 1331px) and (max-width: 1599px)': {
      width: "calc(100% + 16px)",
    },
  },
  continueGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  dataRoot: {
    display: "flex",
    alignItems: "center",
    '@media (min-width: 900px) and (max-width: 1200px)': {
      marginLeft: "auto",
    },
    '@media (max-width: 900px)': {
      marginLeft: "auto",
      width: "70%",
      marginRight: "auto",
    },
    '@media (max-width: 600px)': {
      marginLeft: "auto",
      width: "100%",
      marginRight: "auto",
      whiteSpace: 'break-spaces !important',
      overflow: 'unset !important',
      workBreak: 'break-word'
    },
  },
  imgData: {
    width: "150px",
    height: "92px",
    objectFit: "contain",
    maxWidth: "-webkit-fill-available",
    background: "#f1f1f1",
    borderRadius: "4px",
    '@media (max-width: 650px)': {
      width: "100%",
      objectFit: "contain",
      height: "80px",
    },
  },
  disableContinueBtn: {
    opacity: "0.5",
  },
  courseColumn: {
    fontWeight: 600,
    marginBottom: 0,
    borderBottom: `1px solid ${ColorsConstant.cardBorders}`,
    paddingBottom: 0
  },
  courseDateColumn: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    fontSize: "12px",
    textTransform: "uppercase",
    color: ColorsConstant.subTitleTextColor,
    "@media (max-width: 767px)": {
      fontSize: "10px",
    },
  },
  courseActionColumn: {
    display: "flex",
    fontSize: "12px",
    textTransform: "uppercase",
    color: ColorsConstant.subTitleTextColor,
    "@media (max-width: 767px)": {
      fontSize: "10px",
    },
  },
  recentCourseContainer: {
    margin: '24px 16px',
    borderRadius: 8,
    paddingBottom: 20
  },
  blankDiv: {
    '@media (max-width: 767px)': {
      display: 'none'
    }
  }
}));

function CourseCard(props) {
  const classes = useStyles();
  var history = useHistory();
  const t = useTranslations();
  const [recentCourseExpiry, setRecentCourseExpiry] = useState(false);
  var recentCourseData = props?.item;
  const theme = useTheme();
  const dateString = recentCourseData?.created?.on;
  const formattedDate = moment(dateString).format("DD-MM-YYYY");
  useEffect(() => {
    if (props?.item?.assignedCourseEndDate) {
      let currentDate = new Date();
      let endDate = new Date(props?.item?.assignedCourseEndDate);
      if (currentDate > endDate) {
        setRecentCourseExpiry(true);
      }
    }
  }, []);
  function handleGoToTestClick() {
    localStorage.setItem("dataKey", recentCourseData.courseId);
    window.dispatchEvent(new Event("storage"));
    reactGaEvent("Course Material", "Go To Course");
    localStorage.setItem("courseName", recentCourseData?.name);
    localStorage.setItem("courseDescription", recentCourseData?.description);
    localStorage.setItem("courseId", recentCourseData?._id);
    history.push("/course-material/content", {
      courseId: recentCourseData.courseId,
      subjectId: "",
      topicId: "",
      subjectDetail: recentCourseData,
      subjectName: "",
      topicName: "",
    });
  }

  const assignedCourseEndDate = recentCourseData?.assignedCourseEndDate; // Example timestamp
  const assignedCourseDueDate = recentCourseData?.assignedCourseDueDate; // Due date in days

  let assignedCourseStartDate;

  try {
    // Validate input data
    if (
        typeof assignedCourseEndDate !== "number" || 
        isNaN(assignedCourseEndDate)
    ) {
        throw new Error("Invalid assignedCourseEndDate: Must be a valid timestamp in milliseconds.");
    }

    if (
        typeof assignedCourseDueDate !== "number" || 
        isNaN(assignedCourseDueDate) || 
        assignedCourseDueDate < 0
    ) {
        throw new Error("Invalid assignedCourseDueDate: Must be a valid positive number.");
    }

    // Calculate the start date
    const dueDateInMilliseconds = assignedCourseDueDate * 24 * 60 * 60 * 1000;
    const calculatedStartDate = assignedCourseEndDate - dueDateInMilliseconds;

    // Validate the calculated timestamp
    if (isNaN(calculatedStartDate) || calculatedStartDate < 0) {
        throw new Error("Invalid calculated start date: Check inputs.");
    }

    // Assign the ISO date string to assignedCourseStartDate
    assignedCourseStartDate = new Date(calculatedStartDate).toISOString();
  } catch (error) {
    console.error("Error calculating assignedCourseStartDate:", error.message);
    assignedCourseStartDate = "Invalid start date"; // Fallback value or handle as required
  }


// Utility function to format date into DD-MM-YYYY
const formatDate = (dateInput) => {
  if (!dateInput) return "Invalid Date";
  
  const date = typeof dateInput === "string" ? new Date(dateInput) : new Date(Number(dateInput));

  if (isNaN(date.getTime())) return "Invalid Date"; // Check for invalid date

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};


// Determine the recentStartDate
const recentStartDate = recentCourseData?.startDate 
    ? formatDate(recentCourseData.startDate) 
    : formatDate(assignedCourseStartDate);


  return (
    <Grid container spacing={2} className={classes.courseContainer}>
      
      <Grid item xs={3} sm={3} className={classes.dataGrid}>
        <div
          className={classes.dataRoot}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <div className={classes.heading}>{recentCourseData?.name}</div>
        </div>
      </Grid>
      <Grid item xs={3} sm={3} className={classes.progressBarAlign}>
        <LinearProgressWithLabel
          value={
            recentCourseData?.progressPercent !== undefined
              ? recentCourseData?.progressPercent
              : 0
          }
        />
      </Grid>
      <Grid item xs={3} sm={2} className={classes.heading}>
        {recentStartDate}
      </Grid>
      <Grid item sm={2} className={classes.blankDiv} />
      <Grid item xs={3} sm={2} className={classes.continueGrid}>
        <Button
          onClick={handleGoToTestClick}
          disabled={recentCourseExpiry}
          className={
            recentCourseExpiry
              ? classes.continueBtn + " " + classes.disableContinueBtn
              : classes.continueBtn
          }
          style={{ border: `1px solid ${theme?.primaryColor}`, color: `${theme?.primaryColor}` }}
        >{t("Continue")}</Button>
      </Grid>
    </Grid>
  );
}

function CurrentCourse() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslations();
  const user = useSelector((state) => state.user);
  
  useEffect(() => {
    user?._id && dispatch(recentCourseListAction(user?._id));
  }, [user?._id]);

  let recentCourseData = useSelector((state) => state.recentCourseList);


  return (
    <Box className={`${classes.recentCourseContainer} commonShadow`}>
      <Box className={classes.fragment}>
        <Box className={classes.title}>{t("Recent courses")}</Box>
      </Box>
      <Box className={classes.wrapper}>
        <Box className={classes.mainDiv}>
          <Grid container spacing={2} className={classes.courseColumn}>
            <Grid item xs={3} sm={3} className={classes.courseActionColumn}>
              <Typography variant="p">{t("COURSES")}</Typography>
            </Grid>
            <Grid item xs={3} sm={3} className={classes.courseActionColumn}>
              <Typography variant="p">{t("PROGRESS")}</Typography>
            </Grid>
            <Grid xs={3} sm={2} item className={classes.courseDateColumn}>
              <Typography variant="p">{t("START DATE")}</Typography>
            </Grid>
            <Grid item sm={2} className={classes.blankDiv} />
            <Grid xs={3} sm={2} />
          </Grid>
          {recentCourseData.recentCourseList?.length ? (
            recentCourseData?.recentCourseList.map((item, i) => {
              return (
                <CourseCard
                  item={item}
                  key={i}
                />
              );
            })
          ) : (
            <Grid item md={8} xs={12}>
              <div className={classes.noCourseFound}>
                {t("No recent courses found!")}
              </div>
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default CurrentCourse;
