import React from 'react';
import MathJax from "react-mathjax4";
import { useTranslations, useStyles } from "examsbook-react-sdk/custom-hooks";
import { editor } from 'examsbook-react-sdk/common';
import {Box} from '@material-ui/core';
/**
 * Single option item of a question..
 * @param props
 * @returns {*}
 * @constructor
 */
function QuestionOptionListItem(props) {
    let { index, item, checked, activeQuestionIndex, onRadioChange, type, optionClass } = props;
    const t = useTranslations();
    const classes = useStyles(styles)();
    let optionChecked = '';
    let optionClassess = '';
    if (!checked) {
        checked = false;
    } else {
        // optionChecked = classes.selectedOption;
    }
    if (optionClass === 'correctoption') {
        optionClassess = classes.correctoption;
    }
    if (optionClass === 'incorrectoption') {
        optionClassess = classes.incorrectoption;
    }

    // convert math type question readable format
    editor(item?.text);
    
    return (
        <Box component="li" className={classes.questionList + ' ' + optionClassess + ' ' + optionChecked}>
            <input id={'option' + index} onClick={(e) => onRadioChange(e, index, activeQuestionIndex, type)}
                   checked={checked} value={item._id} type={type === 'MultiChoice' ? "checkbox" : "radio"}
                   className={classes.bullets} onChange={e => {
            }}/>
            <Box component="label" htmlFor={'option' + index} className={classes.answertext}>
                <Box className={classes.mathTextleft}>
                    <MathJax.Context input="tex">
                        <MathJax.Html html={item.text}/>
                    </MathJax.Context>
                </Box>
            </Box>
            <Box className="correctstatus"><span></span>{t('Correct')}</Box>
            <Box className="incorrectstatus"><span></span>{t('WRONG')}</Box>
        </Box>
    );
}

const styles = theme => ({
    selectedOption: {
        '& label': {
            fontWeight: 'bold'
        }
    },
    bullets: {
        width: "20px",
        height: "20px",
        lineHeight: "30px",
        textAlign: "center",
        display: "inline-block",
        background: "#000",
        borderRadius: "100%",
        accentColor: '#0C3450',
        margin: "15px",
        color: "#fff",
        cursor: "pointer",
        cssFloat: "right"
    },
    answertext: {
        float: "right",
        width: "calc(100% - 40px)",
        wordWrap: "break-word",
        display: "block",
        lineHeight: "24px",
        cursor: "pointer",
        fontSize: "19px",
        color: "#000000B2",
        margin: "-15px -50px -15px 0px",
        '@media (max-width: 767px)': {
            fontSize: "18px",
        },
    },
    mathTextleft: {
        display: "inline-block"
    },
    questionList: {
        '-webkit-user-select': 'none',
        '-webkit-touch-callout': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none',
        '& .correctstatus': {
            display: "none",
            position: "absolute",
            right: "3px",
            bottom: "0",
            fontSize: "12px",
        },
        '& .incorrectstatus': {
            display: "none",
            position: "absolute",
            right: "6px",
            bottom: "0",
            fontSize: "12px"
        },
    },
    correctoption: {
        border: '1px solid #00BFA5 !important',
        background: '#5DF2D60C',
        '&:after': {
            background: '#00BFA5 !important',
            color: '#fff !important'
        },
        '& .correctstatus': {
            display: "block",
            color: "#00BFA5",
            top: "18px",
            right: "40px",
            '&:before': {
                width: "10px",
                height: "2px",
                background: "#00BFA5",
                content: "''",
                position: "absolute",
                left: "-14px",
                top: "6px",
                transform: "rotate(-45deg)"
            },
            '&:after': {
                width: "4px",
                height: "2px",
                background: "#00BFA5",
                content: "''",
                position: "absolute",
                left: "-16px",
                top: "7px",
                transform: "rotate(45deg)"
            }
        }
    },
    incorrectoption: {
        border: '1px solid #BF0606 !important',
        background: '#bf060612',
        '&:after': {
            background: '#bf0606 !important',
            color: '#fff !important'
        },
        '& .incorrectstatus': {
            display: "block",
            color: "#BF0606",
            top: "18px",
            right: "40px",
            '&:before': {
                width: "10px",
                height: "2px",
                background: "#BF0606",
                content: "''",
                position: "absolute",
                left: "-14px",
                top: "6px",
                transform: "rotate(-45deg)"
            },
            '&:after': {
                width: "10px",
                height: "2px",
                background: "#BF0606",
                content: "''",
                position: "absolute",
                left: "-14px",
                top: "6px",
                transform: "rotate(45deg)"
            }
        }
    },

});

export default QuestionOptionListItem;
